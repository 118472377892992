import Cookies from 'js-cookie'

const TokenKey = 'YTCQ-Token'
const user = 'YTCQ-user'

//token
export function getToken() {
	return Cookies.get(TokenKey)
}
export function setToken(token) {
	const time = new Date(new Date() * 1 +  1000 * 60 * 60 * 24 * 7);  //7天
	return Cookies.set(TokenKey, token, {
		expires: 30
	});
}
export function removeToken() {
	return Cookies.remove(TokenKey)
}
//用户信息
export function getUserInfo() {
	// console.log(Cookies.get(user),89898)
	return Cookies.get(user) ? JSON.parse(Cookies.get(user)) : '';
}
export function setUserInfo(res) {
	return Cookies.set(user, res, {
		expires: 30
	});
}
export function removeUserInfo() {
	return Cookies.remove(user)
}


export function getRouter() {
	// console.log(Cookies.get(user),89898)
	return Cookies.get('router') ? JSON.parse(Cookies.get('router')) : [];
}
export function setRouter(res) {
	return Cookies.set('router', res, {
		expires: 30
	});
}
