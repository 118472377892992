import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './common/common.less';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.config.productionTip = false;
Vue.use(ElementUI);
import moment from 'moment';
Vue.prototype.$moment = moment
const echarts = require('echarts');
import yongtaixian from '@components/json/yongtaixian.json';
echarts.registerMap('yongtaixian', yongtaixian);
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
