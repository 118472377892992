import Vue from 'vue';
import VueRouter from 'vue-router';
import defined from '@common/defined.json';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location)
		.catch(err => err);
};
import Login from '../views/login/index.vue';

const layout = () => import('../layout/index.vue');
const Home = () => import('../views/home/index.vue');
const ProjectPublicity = () => import('../views/projectPublicity/index.vue');
const ProjectDetails = () => import('../views/projectPublicity/projectDetails.vue');
const publicityDetail = () => import('../views/projectPublicity/publicityDetail.vue');
const OnlineBidding = () => import('../views/onlineBidding/index.vue');
const BiddingDetails = () => import('../views/onlineBidding/biddingDetails.vue');
const DataCenter = () => import('../views/datacenter/index.vue');
const PersonalCenter = () => import('../views/personalCenter/index.vue');
const Financial = () => import('../views/financial/index.vue');
const FinancialDetails = () => import('../views/financial/financialDetails.vue');
const InfoCenter = () => import('../views/infocenter/index.vue');
const InfoDetail = () => import('../views/infocenter/infoDetail.vue');
const SupplyAndDemand = () => import('../views/supplyAndDemand/index.vue');
const ContactUs = () => import('../views/contactus/index.vue');
const CountrySetting = () => import('../views/countrySetting/index.vue');
const CountryDetail = () => import('../views/countrySetting/countryDetail.vue');
const BusinessGuide = () => import('../views/businessguide/index.vue');
const DownLoads = () => import('../views/downloads/index.vue');

const GuideDetail = () => import('../views/businessguide/guideDetail.vue');
Vue.use(VueRouter);

const routes = [{
		path: '*',
		redirect: '/layout',
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			title: '登录页',
		},
	},
	{
		path: '/datacenter',
		name: 'datacenter',
		component: DataCenter,
		meta: {
			title: '数据中心',
		},
	},
	{
		path: '/layout',
		name: 'layout',
		component: layout,
		meta: {
			title: '首页',
		},
		redirect: {
			name: 'home'
		},
		children: [{
				path: 'home',
				name: 'home',
				component: Home,
				meta: {
					//title: '首页',
					menu: []
				}
			},
			{
				path: 'projectPublicity',
				name: 'projectPublicity',
				component: ProjectPublicity,
				meta: {
					title: '公示栏',
					menu: defined.projectPublicity
				}
			},
			{
				path: 'projectDetails',
				name: 'projectDetails',
				component: ProjectDetails,
				meta: {
					title: '项目详情',
					menu: []
				}
			},
			{
				path: 'publicityDetail',
				name: 'publicityDetail',
				component: publicityDetail,
				meta: {
					title: '项目详情',
					menu: []
				}
			},
			{
				path: 'onlineBidding',
				name: 'onlineBidding',
				component: OnlineBidding,
				meta: {
					title: '网上竞价',
					menu: defined.onlineBidding
				}
			},
			{
				path: 'biddingDetails',
				name: 'biddingDetails',
				component: BiddingDetails,
				meta: {
					title: '竞价详情',
					menu: []
				}
			},
			{
				path: 'infocenter',
				name: 'infocenter',
				component: InfoCenter,
				meta: {
					title: '资讯中心',
					menu: defined.infocenter
				}
			},
			{
				path: 'infoDetail',
				name: 'infoDetail',
				component: InfoDetail,
				meta: {
					title: '资讯中心详情',
					menu: []
				}
			},
			{
				path: 'businessguide',
				name: 'businessguide',
				component: BusinessGuide,
				meta: {
					title: '业务指南',
					menu: defined.businessguide
				}
			},

			{
				path: 'downloads',
				name: 'downloads',
				component: DownLoads,
				meta: {
					title: '资料下载',
					menu: defined.downloads
				}
			},
			{
				path: 'guideDetail',
				name: 'guideDetail',
				component: GuideDetail,
				meta: {
					title: '业务指南详情',
					menu: []
				}
			},
			{
				path: 'supplyAndDemand',
				name: 'supplyAndDemand',
				component: SupplyAndDemand,
				meta: {
					title: '供需发布',
					menu: defined.supplyAndDemand
				}
			},
			{
				path: 'countrySetting',
				name: 'countrySetting',
				component: CountrySetting,
				meta: {
					title: '乡土风情',
					menu: defined.countrySetting
				}
			},
			{
				path: 'countryDetail',
				name: 'countryDetail',
				component: CountryDetail,
				meta: {
					title: '永泰农特详情',
					menu: [],
				}
			},
			{
				path: 'financial',
				name: 'financial',
				component: Financial,
				meta: {
					title: '金融产品',
					menu: []
				}
			},
			{
				path: 'financialDetails',
				name: 'financialDetails',
				component: FinancialDetails,
				meta: {
					title: '金融产品详情',
					menu: []
				}
			},
			{
				path: 'contactus',
				name: 'contactus',
				component: ContactUs,
				meta: {
					title: '联系我们',
					menu: defined.contactus
				}
			}, {
				path: 'personalCenter',
				name: 'personalCenter',
				component: PersonalCenter,
				meta: {
					title: '个人中心',
					menu: defined.personalCenter
				}
			}
		]
	},
];

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes,
});

export default router;
