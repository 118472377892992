import router from '@/router'
import axios from 'axios';
import { Message } from 'element-ui';
import {baseURL} from '@common/config';
import {getToken} from '@utils/setCookie';
import store from '../store/'
// console.log(router,999999999)

const service = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
		//'X-Access-Token': getToken()
	},
})
// request发起请求
service.interceptors.request.use(config => {
	if (store.getters["user/token"]) {
		config.headers['X-Access-Token'] = store.getters["user/token"]
	}
	return config
}, error => {
	//console.log(error)
	Promise.reject(error)
})

// respone返回信息
service.interceptors.response.use(
	response => {
		const res = response.data;
		//console.log(router.currentRoute.name,2121212)
		if (res.code === 401) {
			Message("登录信息过期，请重新登录", {
			    type: 'wanring'
			})
			store.dispatch('user/loginOut').then(() => {
				router.push({
					path: router.currentRoute.name === 'personalCenter' ? '/home' : '/login',
					// path: '/login',
				});
			});
		}
		return res;
	},
	error => {
		return Promise.reject(error)
	})
export default service
