<template>
	<div>
		<div class="pages">
			<img src="../../assets/login/loginBg.png" class="loginBg">
			<img src="../../assets/login/logo.png" class="logo">
			<!--      登录页面-->
			<div class="content" v-if="isExitAccount && !isForgetPass">
				<h2>欢迎
					<div>登录</div>
				</h2>
				<div class="layout-row-center typeBox">
					<div :class="isAuthCode ? 'type cursorP active' : 'type cursorP'" @click="open()">
						<div class="typeTitle">验证码登录</div>
					</div>
					<div :class="isAuthCode ? 'type cursorP' : 'type cursorP active'" @click="close()">
						<div class="typeTitle">账号登录</div>
					</div>
				</div>

				<!--        验证码登录部分-->
				<div v-if="isAuthCode">
					<div class="fromBox">
						<div class="layout-row-center fromTitle">
							<img src="../../assets/login/tel.png">
							<div>手机号码</div>
						</div>
						<div class="fromContent">
							<input type="text" v-model="form.tel" placeholder="请输入手机号码">
						</div>
					</div>
					<div class="fromBox">
						<div class="layout-row-center fromTitle">
							<img src="../../assets/login/code.png">
							<div>验证码</div>
						</div>
						<div class="fromContent">
							<input type="text" v-model="form.code" style="width: calc(100% - 135px)"
								placeholder="请输入验证码">
							<div class="cursorP code" @click="!hasCode && getCode(form.tel)"
								:style="{'color':hasCode ? '#999' : '#1FC386','cursor':hasCode ? 'default' : 'pointer'}">
								{{hasCode ? `${countDown}s后重新获取` : '获取验证码'}}</div>
						</div>
					</div>
					<div class="cursorP login" @click="login('1')">
						登&nbsp;&nbsp;&nbsp;&nbsp;录
					</div>
					<div class="cursorP noAccount" @click="isExitAccount = false">
						没有账号?
					</div>
				</div>
				<!--        账号登录部分-->
				<div v-if="!isAuthCode">
					<div class="fromBox">
						<div class="layout-row-center fromTitle">
							<img src="../../assets/login/tel.png">
							<div>账号</div>
						</div>
						<div class="fromContent">
							<input type="text" v-model="formA.account" placeholder="请输入账号">
						</div>
					</div>
					<div class="fromBox">
						<div class="layout-row-center fromTitle">
							<img src="../../assets/login/password.png">
							<div>密码</div>
							<img v-if="loginMessage" style="margin-left: 20px" src="../../assets/login/wrong.png"
								alt="">
							<div v-if="loginMessage" style="color: #ED364B">{{loginMessage}}</div>
						</div>
						<div class="fromContent">
							<input :type="isPass ? 'password' : 'text'" v-model="formA.password"
								style="width: calc(100% - 20px)" placeholder="请输入密码">
							<div class="cursorP tips">
								<img v-if="isPass" src="../../assets/login/hidden.png" @click="isPass = false">
								<img v-if="!isPass" src="../../assets/login/show.png" @click="isPass = true">
							</div>
						</div>
					</div>
					<div class="layout-space-center" style="margin-top: 70px">
						<div class="cursorP login" style="margin-top: 0;width: 260px" @click="login('2')">
							登&nbsp;&nbsp;&nbsp;&nbsp;录
						</div>
						<div class="cursorP layout-center forget" @click="isForgetPass = true">
							忘记密码
						</div>
					</div>
					<div class="cursorP noAccount" @click="isExitAccount = false">
						没有账号?
					</div>
				</div>
			</div>

			<!--      注册页面-->
			<div class="contents" v-if="!isExitAccount && !isForgetPass">
				<img class="cursorP" src="../../assets/login/goback.png" @click="isExitAccount = true">
				<h2>快速
					<div>注册</div>
				</h2>
				<div class="form">
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/tel.png">
							<div>手机号码</div>
						</div>
						<div class="formContent">
							<input type="text" v-model="formB.tel" placeholder="请输入手机号码">
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/password.png">
							<div>密码</div>
						</div>
						<div class="formContent">
							<input :type="isPassA ? 'password' : 'text'" v-model="formB.password"
								style="width: calc(100% - 20px)" placeholder="请输入密码">
							<div class="cursorP tips">
								<img v-if="isPassA" src="../../assets/login/hidden.png" @click="isPassA = false">
								<img v-if="!isPassA" src="../../assets/login/show.png" @click="isPassA = true">
							</div>
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/password.png">
							<div>确认密码</div>
						</div>
						<div class="formContent">
							<input :type="isPassB ? 'password' : 'text'" v-model="formB.passwordTwo"
								style="width: calc(100% - 20px)" placeholder="请输入密码">
							<div class="cursorP tips">
								<img v-if="isPassB" src="../../assets/login/hidden.png" @click="isPassB = false">
								<img v-if="!isPassB" src="../../assets/login/show.png" @click="isPassB = true">
							</div>
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/xingming.png">
							<div>真实姓名</div>
						</div>
						<div class="formContent">
							<input type="text" v-model="formB.name" placeholder="请输入真实姓名">
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/card.png">
							<div>身份证号</div>
						</div>
						<div class="formContent">
							<input type="text" v-model="formB.idCard" placeholder="请输入身份证号">
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/code.png">
							<div>验证码</div>
						</div>
						<div class="formContent">
							<input type="text" v-model="formB.code" style="width: calc(100% - 135px)"
								placeholder="请输入验证码">
							<div class="cursorP code" @click="!hasCode && getCode(formB.tel)"
								:style="{'color':hasCode ? '#999' : '#1FC386','cursor':hasCode ? 'default' : 'pointer'}">
								{{hasCode ? `${countDown}s后重新获取` : '获取验证码'}}</div>
						</div>
					</div>
				</div>

				<div class="layout-center cursorP register" @click="Register()">
					立&nbsp;&nbsp;即&nbsp;&nbsp;注&nbsp;&nbsp;册
				</div>
			</div>
			<!--      修改密码-->
			<div class="contents" v-if="isForgetPass">
				<img class="cursorP" src="../../assets/login/goback.png" @click="isForgetPass = false">
				<h2>修改
					<div>密码</div>
				</h2>
				<div class="form">
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/tel.png">
							<div>手机号码</div>
						</div>
						<div class="formContent">
							<input type="text" v-model="formC.tel" placeholder="请输入手机号码">
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/password.png">
							<div>新密码</div>
						</div>
						<div class="formContent">
							<input :type="isPassC ? 'password' : 'text'" v-model="formC.password"
								style="width: calc(100% - 20px)" placeholder="请输入新密码">
							<div class="cursorP tips">
								<img v-if="isPassC" src="../../assets/login/hidden.png" @click="isPassC = false">
								<img v-if="!isPassC" src="../../assets/login/show.png" @click="isPassC = true">
							</div>
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/password.png">
							<div>确认新密码</div>
						</div>
						<div class="formContent">
							<input :type="isPassD ? 'password' : 'text'" v-model="formC.passwordTwo"
								style="width: calc(100% - 20px)" placeholder="请输入新密码">
							<div class="cursorP tips">
								<img v-if="isPassD" src="../../assets/login/hidden.png" @click="isPassD = false">
								<img v-if="!isPassD" src="../../assets/login/show.png" @click="isPassD = true">
							</div>
						</div>
					</div>
					<div class="formBox">
						<div class="layout-row-center formTitle">
							<img src="../../assets/login/code.png">
							<div>验证码</div>
						</div>
						<div class="formContent">
							<input type="text" v-model="formC.code" style="width: calc(100% - 135px)"
								placeholder="请输入验证码">
							<div class="cursorP code" @click="!hasCode && getCode(formC.tel)"
								:style="{'color':hasCode ? '#999' : '#1FC386','cursor':hasCode ? 'default' : 'pointer'}">
								{{hasCode ? `${countDown}s后重新获取` : '获取验证码'}}</div>
						</div>
					</div>
				</div>
				<div class="layout-center cursorP register" @click="updatePass()">
					修&nbsp;&nbsp;改&nbsp;&nbsp;密&nbsp;&nbsp;码
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	import fetch from '@utils/fetch.js';
	import {
		validPhone,
		validIDCard
	} from '@utils/validate.js';
	let interval = null;
	export default {
		data() {
			return {
				isAuthCode: true, //是否是验证码登录
				isPass: true, //是否是密码输入框
				isPassA: true, //是否是密码输入框
				isPassB: true, //是否是密码输入框
				isPassC: true, //是否是密码输入框
				isPassD: true, //是否是密码输入框
				isExitAccount: true, //是否有账号
				isForgetPass: false, //是否忘记密码
				hasCode: false, //是否获取到验证码
				countDown: 60, //验证码倒计时
				loginMessage: '', //用户登录页登录错误提示消息
				form: {
					tel: '',
					code: '',
				}, //验证码登录表单
				formA: {
					account: '',
					password: '',
				}, //账号密码登录表单
				formB: {
					tel: '',
					password: '',
					passwordTwo: '',
					name: '',
					idCard: '',
					code: '',
				}, //注册表单
				formC: {
					tel: '',
					password: '',
					passwordTwo: '',
					code: '',
				}, //修改密码表单
			};
		},
		created() {
			if(this.$route.query.res){
				this.isExitAccount = false;
			}
			let that = this;
			document.onkeydown = function(e) {
				if (e.keyCode == 13 && that.$route.name === 'login') {
					if(that.isExitAccount && !that.isForgetPass && that.isAuthCode){//验证码登录
						that.login('1')
					}
					if(that.isExitAccount && !that.isForgetPass && !that.isAuthCode){//账号密码登录
						that.login('2')
					}
				}
			}
		},
		methods: {
			open() {
				this.isAuthCode = true;
			},
			close() {
				this.isAuthCode = false;
			},
			//获取验证码
			getCode(phone) {
				if (!phone) {
					this.$message.error('请输入手机号');
					return;
				};
				if (!validPhone(phone)) {
					this.$message.error('手机号输入有误');
					return;
				};
				let that = this;
				fetch({
					url: '/app/wxApi/getSms',
					method: 'get',
					params: {
						desMobile: phone,

					},
				}).then(() => {
					that.hasCode = true;
					interval = setInterval(() => {
						that.countDown--;
						if (that.countDown == 0) {
							clearInterval(interval);
							that.countDown = 60;
							that.hasCode = false;
						};
					}, 1000);
				}).finally(() => {});
			},
			//登录方法
			login(type) {
				//验证码登录
				if (type == 1) {
					console.log(this.form);
					if (!this.form.tel) {
						this.$message({
							message: '请输入手机号',
							type: 'warning'
						});
						return;
					}
					if (!validPhone(this.form.tel)) {
						this.$message({
							message: '手机号输入有误',
							type: 'warning'
						});
						return;
					}
					if (!this.form.code) {
						this.$message({
							message: '请输入验证码',
							type: 'warning'
						});
						return;
					}
					fetch({
						url: '/applets/user/phoneLogin',
						method: 'post',
						data: {
							phone: this.form.tel,
							smscode: this.form.code,
						},
					}).then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.$store.dispatch('user/setUserInfo',res.result).then(()=>{
								this.$message({
									message: '登录成功',
									type: 'success'
								});
								this.$router.push({
									path: "/home",
								});
							});
						} else {
							this.$message.error(res.message);
						}
					}).finally(() => {});
				}
				//账号登录
				if (type == 2) {
					if (!this.formA.account) {
						this.$message({
							message: '请输入账号',
							type: 'warning'
						});
						return;
					}
					if (!this.formA.password) {
						this.$message({
							message: '请输入密码',
							type: 'warning'
						});
						return;
					}
					fetch({
						url: '/applets/user/login',
						method: 'post',
						data: {
							username: this.formA.account,
							password: this.formA.password,
						},
						dataType: "json",
					}).then((res) => {
						if (res.code === 500) {
							this.loginMessage = res.message;
							this.$message.error(res.message);
						}
						if (res.code === 200) {
							this.loginMessage = '';
							this.$store.dispatch('user/setUserInfo',res.result).then(()=>{
								this.$message({
									message: '登录成功',
									type: 'success'
								});
								this.$router.push({
									path: "/home",
								});
							});
						}
					}).finally(() => {});
				}
			},
			//注册方法
			Register() {
				if (!this.formB.tel) {
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
					return;
				}
				if (!validPhone(this.formB.tel)) {
					this.$message({
						message: '手机号输入有误',
						type: 'warning'
					});
					return;
				}
				if (!this.formB.password) {
					this.$message({
						message: '请输入密码',
						type: 'warning'
					});
					return;
				}
				if (this.formB.password !== this.formB.passwordTwo) {
					this.$message({
						message: '请确认两次输入的密码一致',
						type: 'warning'
					});
					return;
				}
				if (!this.formB.name) {
					this.$message({
						message: '请输入真实姓名',
						type: 'warning'
					});
					return;
				}
				if (!this.formB.idCard) {
					this.$message({
						message: '请输入身份证号',
						type: 'warning'
					});
					return;
				}
				if (!validIDCard(this.formB.idCard)) {
					this.$message({
						message: '身份证号输入有误',
						type: 'warning'
					});
					return;
				}


				if (!this.formB.code) {
					this.$message({
						message: '请输验证码',
						type: 'warning'
					});
					return;
				}
				fetch({
					url: '/applets/user/register',
					method: 'post',
					data: {
						card: this.formB.idCard,
						password: this.formB.password,
						phone: this.formB.tel,
						realName: this.formB.name,
						smscode: this.formB.code,
					},
					dataType: "json",
				}).then((res) => {
					if (res.code === 200) {
						this.$message({
							message: '注册成功',
							type: 'success'
						});
						this.isExitAccount = true //是否有账号
						this.isForgetPass = false //是否忘记密码
						this.hasCode = false
						this.countDown = 60
					} else {
						this.$message.error(res.message);
					}
				}).finally(() => {});
			},
			//修改密码方法
			updatePass() {
				if (!this.formC.tel) {
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
					return;
				}
				if (!validPhone(this.formC.tel)) {
					this.$message({
						message: '手机号输入有误',
						type: 'warning'
					});
					return;
				}
				if (!this.formC.password) {
					this.$message({
						message: '请输入新密码',
						type: 'warning'
					});
					return;
				}
				if (this.formC.password !== this.formC.passwordTwo) {
					this.$message({
						message: '请确认两次输入的密码一致',
						type: 'warning'
					});
					return;
				}
				if (!this.formC.code) {
					this.$message({
						message: '请输验证码',
						type: 'warning'
					});
					return;
				}
				fetch({
					url: '/applets/user/editPassword',
					method: 'put',
					data: {
						password: this.formC.password,
						phone: this.formC.tel,
						smscode: this.formC.code,
					},
					dataType: "json",
				}).then((res) => {
					if (res.code === 200) {
						this.$message({
							message: '密码修改成功',
							type: 'success'
						});
						this.isExitAccount = true //是否有账号
						this.isForgetPass = false //是否忘记密码
						this.hasCode = false
						this.countDown = 60
					} else {
						this.$message.error(res.message);
					}
				}).finally(() => {});
			},
		},
	};
</script>
<style lang="less">
	.pages {
		width: 100%;
		height: 1080px;
		position: relative;
		font-size: 0;

		.loginBg {
			width: 1320px;
			height: 100%;
		}

		.logo {
			position: absolute;
			left: 60px;
			top: 60px;
			width: 496px;
			height: 64px;
		}

		.content {
			position: absolute;
			right: 0;
			top: 0;
			width: 600px;
			height: 1080px;
			padding: 220px 80px;
			box-sizing: border-box;
			background: #FFF;

			h2 {
				font-size: 40px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				display: flex;
				margin-bottom: 60px;

				div {
					font-size: 40px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #1FC386;
				}
			}

			.typeBox {
				padding-bottom: 40px;

				.type {
					&:first-child {
						margin-right: 40px;
					}

					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;

					.typeTitle {
						margin-bottom: 9px;
					}
				}

				.active {
					color: #1FC387;

					&:after{
						content: '';
						display: block;
						width: 100%;
						height: 2px;
						background: #1FC387;
					}
				}
			}

			.fromBox {
				&:not(:first-of-type) {
					margin-top: 40px;
				}

				.fromTitle {
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					margin-bottom: 20px;

					img {
						margin-right: 10px;
					}
				}

				.fromContent {
					width: 100%;
					height: 60px;
					padding: 5px 19px;
					box-sizing: border-box;
					background: #F2F4F9;
					border: 1px solid #E0E5EF;
					border-radius: 4px;
					display: flex;
					align-items: center;

					input {
						border: none;
						background: none;
						width: 100%;
						height: 100%;
						font-size: 20px;
						font-family: PingFang SC;
						font-weight: 500;
					}

					input::placeholder {
						color: #C6CFE0;
					}

					input:focus {
						box-sizing: border-box;
						outline: 0px;
						border: none;
					}

					/*ie*/
					input::-ms-reveal {
						display: none;
					}

					input::-ms-clear {
						display: none;
					}

					/*edge*/
					input::-o-clear {
						display: none;
					}

					.code {
						width: 135px;
						height: 100%;
						font-size: 20px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #1FC386;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.tips {
						width: 20px;
						height: 100%;
						display: flex;
						align-items: center;
					}
				}

			}

			.login {
				width: 100%;
				height: 60px;
				margin-top: 70px;
				background: #1FC386;
				border-radius: 4px;
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.forget {
				width: 160px;
				height: 60px;
				border: 1px solid #1FC386;
				border-radius: 4px;
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #1FC386;
			}

			.noAccount {
				margin-top: 19px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
			}
		}

		.contents {
			position: absolute;
			right: 0;
			top: 0;
			width: 600px;
			height: 1080px;
			padding: 40px 80px;
			box-sizing: border-box;
			background: #FFF;

			h2 {
				font-size: 40px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				display: flex;
				margin: 35px 0;

				div {
					font-size: 40px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #1FC386;
				}
			}

			.form {
				width: 100%;

				.formBox {
					&:not(:first-of-type) {
						margin-top: 30px;
					}

					.formTitle {
						font-size: 20px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #333333;
						margin-bottom: 20px;

						img {
							margin-right: 10px;
						}
					}

					.formContent {
						width: 100%;
						height: 50px;
						padding: 5px 19px;
						box-sizing: border-box;
						background: #F2F4F9;
						border: 1px solid #E0E5EF;
						border-radius: 4px;
						display: flex;
						align-items: center;

						input {
							border: none;
							background: none;
							width: 100%;
							height: 100%;
							font-size: 20px;
							font-family: PingFang SC;
							font-weight: 500;
						}

						input::placeholder {
							color: #C6CFE0;
						}

						input:focus {
							box-sizing: border-box;
							outline: 0px;
							border: none;
						}

						/*ie*/
						input::-ms-reveal {
							display: none;
						}

						input::-ms-clear {
							display: none;
						}

						/*edge*/
						input::-o-clear {
							display: none;
						}

						.code {
							width: 135px;
							height: 100%;
							font-size: 20px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #1FC386;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.tips {
							width: 20px;
							height: 100%;
							display: flex;
							align-items: center;
						}
					}
				}
			}

			.register {
				width: 100%;
				height: 50px;
				margin-top: 60px;
				background: #1FC386;
				border-radius: 4px;
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
			}
		}

	}
</style>
