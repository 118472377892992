 // 验证电话号码格式
export function validPhone(str) {
  const reg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
  return reg.test(str);
}

 // 验证身份证号码格式
export function validIDCard(str) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(str);
}


export function validateMoney(str) {
  const reg = /^[1-9]\d*(.[0-9]{1,2})?$|0.[0-9]{1,2}$/
  return reg.test(str);
}
