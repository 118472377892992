<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {};
</script>
<style lang="less">
	//@import "./common/common";

	#app {
		background: #F7F7F7;
	}
</style>
