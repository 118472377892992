import {
	setToken,
	setUserInfo,
	getUserInfo,
	getToken,
	removeToken,
	removeUserInfo,
	getRouter,
	setRouter
} from '@utils/setCookie';

const state = {
	userInfo: getUserInfo(),
	token: getToken(),
	router:getRouter()
}

const getters = {
	userInfo(state) {
		if (state.userInfo != "" && state.userInfo != null) {
			if (typeof state.userInfo == 'string') {
				return JSON.parse(state.userInfo);
			} else {
				return state.userInfo;
			}
		} else {
			return state.userInfo;
		}
	},
	token(state) {
		return state.token;
	}
}

const actions = {
	setUserInfo({
		commit
	}, info) {
		return new Promise((resolve, reject) => {
			setToken(info.token);
			setUserInfo(JSON.stringify(info.userInfo));
			commit('pushUserInfo', info.userInfo);
			commit('pushToken', info.token);
			resolve()
		})
	},
	//退出登录
	loginOut({
		commit
	}) {
		return new Promise((resolve, reject) => {
			removeToken();
			removeUserInfo();
			commit('pushUserInfo', '');
			commit('pushToken', '');
			resolve()
		})
	},
}

const mutations = {
	pushUserInfo(state, userInfo) {
		state.userInfo = userInfo;
	},
	pushToken(state, token) {
		state.token = token;
	},
	pushRouter(state, arr){
		setRouter(JSON.stringify(arr))
		state.router = arr;
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
