// export const baseURL = 'http://192.168.2.148:16834/'; //陈
// export const baseURL = 'http://192.168.110.207:16834/';	//谢
// export const baseURL = 'http://192.168.2.98:16834/';	//蔡

//  export const baseURL = 'http://192.168.8.7:16834' //强
//  export const fileURL = 'http://192.168.8.7:16834/sys/common/static/';


//  export const baseURL = 'http://192.168.8.185:16834' //培
//  export const fileURL = 'http://192.168.8.185:16834/sys/common/static/';


// export const baseURL = 'https://www.ytxnccqlzfwzx.com/ytcq-api-ytcqtest/'  //测试
// export const fileURL = 'https://www.ytxnccqlzfwzx.com/ytcq-api-ytcqtest/sys/common/static/'

// export const baseURL = 'https://www.laoyouhui.top/ytcq-api/'
// export const fileURL = 'https://www.laoyouhui.top/ytcq-api/sys/common/static/';

export const baseURL='https://www.ytxnccqlzfwzx.com/ytcq-api/'  //正式
export const fileURL ='https://www.ytxnccqlzfwzx.com/ytcq-api/sys/common/static/'

